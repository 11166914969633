import { ArrowBack } from "@mui/icons-material";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { getProjectDetailURL } from "../../components/Project/utils";
import useProjectId from "../../hooks/useProjectId";
import { userZipState } from "../../jotai/user";
import { Button, ButtonSizes, ButtonThemes, Link } from "../../library";
import { getParam } from "../../utils";
import { bgColorClass } from "../../utils/colors";
import { getSearchPath } from "../../utils/format";

export default function MinimalPageHeader() {
  const query = getParam("query");
  const projectId = useProjectId();
  const userZip = useAtomValue(userZipState);

  const href = useMemo(() => {
    if (projectId) return getProjectDetailURL(projectId);
    return getSearchPath({
      query,
      queryZip: userZip,
      analyticsSearchSource: "project-header-back-button",
    }).href;
  }, [userZip, projectId, query]);

  return (
    <header
      className={clsx(
        "sticky top-0 z-3 px-6 pb-1 h-12 flex items-end",
        bgColorClass.neutral.subtlest.enabled
      )}
    >
      <Button
        as={Link}
        size={ButtonSizes.SMALL}
        theme={ButtonThemes.TERTIARY_DARK}
        badgeProps={{
          Icon: ArrowBack,
        }}
        linkProps={{
          href,
          underline: false,
          newWindow: false,
        }}
      >
        Return to search results
      </Button>
    </header>
  );
}
