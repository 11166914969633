import { atom } from "jotai";
import { atomWithRefresh, loadable } from "jotai/utils";
import {
  ApiService,
  type BaseSupplier,
  type ItemTypeEnum,
  type Project,
  type ProjectNote,
  type ProjectTask,
  type PurchaseOption,
  type SupplierListResponse,
} from "../generated";
import { handleError } from "../utils/generatedApi";
import {
  trackRemoveSupplierFromRequest,
  trackSelectPurchaseOption,
  trackToggleProjectTaskStatus,
} from "../utils/tracking";
import { pageTypeState } from "./page";
import { requestIDState } from "./search";
import { datasetContext, intakeFormEnabledState } from "./user";

export const savedProjectState = atom<{
  id: string;
  type: ItemTypeEnum;
} | null>(null);

export const updatePurchaseOptionAtom = atom(
  null,
  async (get, set, option: PurchaseOption) => {
    const requestID = get(requestIDState);
    const projectContext = get(projectContextState);
    if (!projectContext?.purchaseRequest) return;

    const originalOption = projectContext.purchaseRequest.selectedOption;
    // Optimistically update state and rollback on an error.
    set(projectContextState, (prev) => {
      if (!prev?.purchaseRequest?.id) return prev;
      return {
        ...prev,
        purchaseRequest: {
          ...prev.purchaseRequest,
          selectedOption: option,
        },
      };
    });

    try {
      const purchaseRequest =
        await ApiService.apiV1PurchaseRequestsPartialUpdate(
          projectContext.purchaseRequest.id,
          {
            selectedOption: option.id,
          }
        );

      trackSelectPurchaseOption({
        requestID,
        projectId: projectContext.id,
        purchaseRequestId: projectContext.purchaseRequest.id,
        selectedOptionId: option.id,
      });
      const project = await ApiService.apiV1ProjectsRetrieve(projectContext.id);
      set(projectContextState, project);
      return purchaseRequest;
    } catch (error) {
      handleError(error);

      set(projectContextState, (prev) => {
        if (!prev?.purchaseRequest?.id) return prev;
        return {
          ...prev,
          purchaseRequest: {
            ...prev.purchaseRequest,
            selectedOption: originalOption,
          },
        };
      });
    }
  }
);

export const includeArchivedAtom = atom(true);

export const projectsAtom = atomWithRefresh(async (get) => {
  const includeArchived = get(includeArchivedAtom);

  return ApiService.apiV1ProjectsList(!includeArchived);
});

export const projectsLoadable = loadable(projectsAtom);

export const preferredSuppliersAtom = atomWithRefresh(async (get) => {
  try {
    const requestId = get(projectContextState)?.purchaseRequest?.id;
    if (requestId) {
      return await ApiService.apiV1PurchaseRequestsSuppliersList(requestId);
    }
    const intentId = get(projectContextState)?.purchaseIntent?.id;
    if (intentId) {
      return await ApiService.apiV1PurchaseIntentsSuppliersList(intentId);
    }
    return [];
  } catch (error) {
    handleError(error);
    return [];
  }
});

export const preferredSuppliersLoadable = loadable(preferredSuppliersAtom);

export const savedSuppliersAtom = atom<SupplierListResponse[]>([]);

export const projectContextState = atom<Project | null>(
  datasetContext?.project || null
);

export const projectPurchaseOptionSuppliersAtom = atomWithRefresh(
  async (get) => {
    const projectContext = get(projectContextState);
    if (!projectContext?.purchaseRequest?.selectedOption) return [];
    return ApiService.apiV1ProjectsPurchaseOptionsSuppliersList(
      projectContext.id,
      projectContext.purchaseRequest.selectedOption.id
    );
  }
);

export const projectPurchaseOptionSuppliersLoadable = loadable(
  projectPurchaseOptionSuppliersAtom
);

export const deleteProjectPurchaseOptionSupplierAtom = atom(
  null,
  async (get, set, payload: Pick<BaseSupplier, "id">) => {
    const projectContext = get(projectContextState);
    if (!projectContext?.purchaseRequest?.selectedOption) return;

    const requestID = get(requestIDState);
    const pageType = get(pageTypeState);

    try {
      await ApiService.apiV1ProjectsPurchaseOptionsSuppliersDestroy(
        projectContext.id,
        projectContext.purchaseRequest.selectedOption.id,
        payload.id
      );
      trackRemoveSupplierFromRequest({
        requestID,
        projectId: projectContext.id,
        purchaseRequestId: projectContext.purchaseRequest.id,
        purchaseOptionId: projectContext.purchaseRequest.selectedOption.id,
        supplierId: payload.id,
        pageType,
      });

      set(projectPurchaseOptionSuppliersAtom);
    } catch (e) {
      handleError(e);
    }
  }
);

export const createProjectNoteErrorAtom = atom<boolean>(false);

export const createProjectNoteAtom = atom(
  null,
  async (get, set, payload: Pick<ProjectNote, "text">) => {
    set(createProjectNoteErrorAtom, false);

    const projectContext = get(projectContextState);
    if (!projectContext) return;

    try {
      const newNote = await ApiService.apiV1ProjectsNotesCreate(
        projectContext.id,
        payload
      );

      set(projectContextState, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          notes: [...prev.notes, newNote],
        };
      });
    } catch (e) {
      set(createProjectNoteErrorAtom, true);
      handleError(e);
    }
  }
);

export const updateTaskStatusAtom = atom(
  null,
  async (get, set, taskId: number, payload: Pick<ProjectTask, "status">) => {
    const projectContext = get(projectContextState);
    if (!projectContext) return;

    const originalTask = projectContext.tasks.find(({ id }) => id === taskId);
    if (!originalTask) return;
    // To make the checkbox experience smooth, optimistically update state, and rollback
    // on an error.
    set(projectContextState, (prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        tasks: prev.tasks.map((task) => {
          if (task.id !== taskId) return task;
          return { ...task, ...payload };
        }),
      };
    });

    try {
      await ApiService.apiV1ProjectsTasksPartialUpdate(
        taskId,
        projectContext.id,
        payload
      );
      const requestID = get(requestIDState);

      trackToggleProjectTaskStatus({
        projectId: projectContext.id,
        requestID,
        purchaseRequestId: projectContext.purchaseRequest?.id,
        taskId,
        status: payload.status,
      });
    } catch (e) {
      handleError(e);

      set(projectContextState, (prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          tasks: prev.tasks.map((task) => {
            if (task.id !== taskId) return task;
            return originalTask;
          }),
        };
      });
    }
  }
);

export const projectConstantsAtom = atom((get) => {
  return get(intakeFormEnabledState)
    ? { name: "request", title: "Request", baseUrl: "/requests" }
    : { name: "project", title: "Project", baseUrl: "/projects" };
});
