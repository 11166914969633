import { Field, Form, Formik, type FormikHelpers } from "formik";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import {
  blaFieldSignupState,
  inferredEntitySignupState,
} from "../../../jotai/signup";
import { userEmailState, userPasswordAtom } from "../../../jotai/user";
import Typography from "../../../library/Typography";
import {
  LoginType,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../../utils/enums";
import { trackSignupFlowFailure } from "../../../utils/tracking";
import {
  type BuyerSignupFormValues,
  type InferredBLA,
  WindowType,
} from "../types";
import useSubmitBuyerInfo from "../useSubmitBuyerInfo";

import FormSubmitButton from "../../../library/form/FormSubmitButton";
import type { Validate } from "../../../library/form/types";
import yup from "../../../utils/yupPhone";
import StepCircle from "./StepCircle";
import { BUYER_ROLE_FIELD, NAME_FIELD } from "./constants";

interface BuyerSignupFormProps {
  onComplete: (redirectUrl: string) => void;
  inviteBLA: InferredBLA | null;
}

export default function BuyerSignupForm({
  onComplete,
  inviteBLA,
}: BuyerSignupFormProps) {
  const email = useAtomValue(userEmailState);
  const password = useAtomValue(userPasswordAtom);
  const blaFromSignup = useAtomValue(blaFieldSignupState);
  const inferredEntity = useAtomValue(inferredEntitySignupState);

  let prefilledBLA = "";
  let prefilledBLAId = null;
  // It's possible for the user to overwrite the inferred BLA or the invite BLA,
  // so only track if it matches buyerProfileFromSignup
  if (blaFromSignup) {
    const {
      governmentAffiliationDisplayName: blaSignupDisplayName,
      governmentAgencyId: blaSignupId,
    } = blaFromSignup;

    if (inferredEntity) {
      if (blaSignupDisplayName === inferredEntity.buyerLeadAgencyDisplayName) {
        prefilledBLA = inferredEntity.buyerLeadAgencyDisplayName;
      }
      if (blaSignupId === inferredEntity.buyerLeadAgencyId) {
        prefilledBLAId = inferredEntity.buyerLeadAgencyId || null;
      }
    } else if (inviteBLA) {
      if (blaSignupDisplayName === inviteBLA.display_name) {
        prefilledBLA = inviteBLA.display_name;
      }
      if (blaSignupId === inviteBLA.id) {
        prefilledBLAId = inviteBLA.id;
      }
    }
  }

  const [loading, formErrorMessage, submitBuyerInfo] = useSubmitBuyerInfo({
    email,
    onComplete,
    prefilledBLA,
    prefilledBLAId,
  });

  const validationSchema = useMemo(() => {
    return [BUYER_ROLE_FIELD, NAME_FIELD].reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, []);

  function trackInvalidForm(error: string) {
    trackSignupFlowFailure({
      emailEntered: email,
      accountType: loginSignupAccountTypes.BUYER,
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error,
      loginExperience: WindowType.Modal,
    });
  }

  async function handleSubmit(
    values: BuyerSignupFormValues,
    helpers: FormikHelpers<BuyerSignupFormValues>
  ) {
    if (!blaFromSignup) {
      trackInvalidForm("Expected buyerProfileFromSignup atom value to exist");
      return;
    }
    return submitBuyerInfo(
      {
        ...values,
        password,
        buyerProfile: blaFromSignup,
      },
      helpers.setFieldError
    );
  }
  // TODO: Use entrypoint to customize copy for 3rd step
  // https://app.shortcut.com/coprocure/story/24980/handle-copy-variants-based-on-entry-point
  const stepFromEntrypoint = "View contract";
  const steps = ["Sign up", "Create account", stepFromEntrypoint];

  return (
    <div className="grid gap-6">
      <div className="flex gap-3">
        {steps.map((text, index) => (
          <StepCircle key={text} text={text} index={index} />
        ))}
      </div>
      <div className="grid gap-4">
        <div className="grid gap-3">
          <Typography
            variant="headline"
            size="sm"
            color="neutral.boldest.enabled"
            emphasis
          >
            Let's set up Pavilion for you
          </Typography>
          <Typography color="neutral.boldest.enabled">
            Tell us about yourself so we can customize your experience.
          </Typography>
        </div>
        <Formik
          enableReinitialize
          validateOnBlur
          initialValues={
            {
              buyerRole: null,
              name: "",
            } as BuyerSignupFormValues
          }
          onSubmit={handleSubmit}
          validationSchema={yup.object(validationSchema)}
        >
          <Form className="grid gap-4">
            <div className="grid gap-3">
              <Field {...BUYER_ROLE_FIELD} editable />
              <Field {...NAME_FIELD} editable />
            </div>
            <FormSubmitButton
              analyticsClassName="analytics-buyer-signup-modal-cta"
              dataTestId="submit-create-account"
              disabled={loading}
              trackInvalidForm={trackInvalidForm}
            >
              Create account
            </FormSubmitButton>
          </Form>
        </Formik>
        {formErrorMessage && (
          <Typography size="sm" color="destructive.default.primary.enabled">
            {formErrorMessage}
          </Typography>
        )}
      </div>
    </div>
  );
}
