import clsx from "clsx";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import _isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";

import { useBindPageParamDict } from "../../hooks/useBindPageParam";
import useDefaultSearchPreferences from "../../hooks/useDefaultSearchPreferences";
import useSearchContractWithParams from "../../hooks/useSearchContractWithParams";
import {
  projectConstantsAtom,
  projectContextState,
} from "../../jotai/projects";
import {
  contractSearchIsLoadingState,
  contractSearchParamsState,
  contractSearchResponseDataState,
  searchResultTypeState,
  supplierSearchResponseDataState,
} from "../../jotai/search";
import {
  approvedSourcesOnlyFilterState,
  contractSourceFilterState,
  diversityCertificationsFilterState,
  singleAwardOnlyFilterState,
} from "../../jotai/searchFilters";
import {
  buyerProfileState,
  isAuthenticatedState,
  userStateState,
  userZipState,
} from "../../jotai/user";
import SearchPage from "../../shared/SearchPage/SearchPage";
import { useShowSidebar } from "../../shared/Sidebar/useShowSidebar";
import Tabs from "../../shared/Tabs";
import { TabLinkSize } from "../../shared/Tabs/TabLink";
import { bgColorClass } from "../../utils/colors";
import { isFeatureEnabled } from "../../utils/features";
import GovAgencyMatch from "./GovAgencyMatch";
import ProjectOverview from "./ProjectOverview";
import type { ContractSearchParams } from "./types";

function useProjectTabs() {
  const projectConstants = useAtomValue(projectConstantsAtom);

  return useMemo(() => {
    return [
      {
        name: "Find suppliers",
        Component: ContractSearch,
        hash: "search",
        dataTestId: "project-search",
      },
      {
        name: `${projectConstants.title} details`,
        Component: ProjectOverview,
        hash: "checklist",
        dataTestId: "project-checklist",
      },
    ];
  }, [projectConstants]);
}

function ContractSearch() {
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const nearbyAgencies = contractResponseData?.agencyData?.relatedAgencies;

  return (
    <div className="px-6 md:px-8">
      <div className="max-w-[75rem] m-auto">
        {contractResponseData && (
          <GovAgencyMatch
            agency={contractResponseData.agencyData?.agency}
            relatedAgencies={nearbyAgencies || []}
          />
        )}
        <SearchPage />
      </div>
    </div>
  );
}

export default function ContractSearchContainer() {
  const userState = useAtomValue(userStateState);
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const defaultSearchPreferences = useDefaultSearchPreferences();
  const isAuthenticated = useAtomValue(isAuthenticatedState);
  const setDiversityCertificationsFilter = useSetAtom(
    diversityCertificationsFilterState
  );
  const setSingleAwardOnlyState = useSetAtom(singleAwardOnlyFilterState);
  const setApprovedSourcesOnlyFilter = useSetAtom(
    approvedSourcesOnlyFilterState
  );
  const setContractSourceFilter = useSetAtom(contractSourceFilterState);
  const contractResponseData = useAtomValue(contractSearchResponseDataState);
  const [searchParams, setSearchParams] = useAtom(contractSearchParamsState);
  const searchResultType = useAtomValue(searchResultTypeState);

  const contractSearchIsLoading = useAtomValue(contractSearchIsLoadingState);
  const supplierResponseData = useAtomValue(supplierSearchResponseDataState);
  const project = useAtomValue(projectContextState);

  const userZip = useAtomValue(userZipState);
  const showSidebar = useShowSidebar();
  const searchContractWithParams = useSearchContractWithParams();
  const tabs = useProjectTabs();

  // NOTE: LIQUID GOLD ANALYTICS & HEAP RELY ON THESE PARAM IN THE URL.
  // PLEASE COORDINATE IF THESE CHANGE.
  useBindPageParamDict(searchParams, setSearchParams);

  useEffect(() => {
    setDiversityCertificationsFilter(
      !!userState && !!userState.showDiversityCertifications
    );
    setSingleAwardOnlyState(!!userState && !!userState.showOnlySingleAward);
  }, [setSingleAwardOnlyState, setDiversityCertificationsFilter, userState]);

  useEffect(() => {
    if (
      !governmentAgency ||
      !governmentAgency.showOnlyApprovedSources ||
      !governmentAgency.approvedSources?.length
    ) {
      return;
    }
    setApprovedSourcesOnlyFilter(true);
  }, [setApprovedSourcesOnlyFilter, governmentAgency]);

  const waitForSearch = useMemo(
    () => _isEmpty(searchParams) || (isAuthenticated && _isEmpty(userState)),
    [searchParams, isAuthenticated, userState]
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Only rerun searches if 'wait for search' changes.
  useEffect(() => {
    if (contractSearchIsLoading || contractResponseData || supplierResponseData)
      return;
    if (waitForSearch) return;
    // pass zip if available
    let update: Partial<ContractSearchParams> = userZip ? { zip: userZip } : {};
    update.collapseBySupplier = searchResultType === "supplier";
    if (!searchParams.filters) {
      update = {
        ...update,
        ...defaultSearchPreferences,
      };
    }
    searchContractWithParams({ newParams: update });
  }, [waitForSearch]);

  const [isFirstSearch, setIsFirstSearch] = useState(true);

  // If the prioritized entity (i.e. the government affiliation) did not have any
  // associated contract search results, then manually add the entity to the list of
  // contract sources, otherwise there is no visual indication that we're filtering by it.
  // biome-ignore lint/correctness/useExhaustiveDependencies: No need to run this check when the setters change.
  useEffect(() => {
    const govAffiliation =
      contractResponseData?.prioritizedEntityMatchData?.agencyInfo.name;
    if (isFirstSearch && govAffiliation) {
      setContractSourceFilter([govAffiliation]);
      setIsFirstSearch(false);
    }
  }, [contractResponseData?.prioritizedEntityMatchData]);

  if (isFeatureEnabled("projectBrowsing") && project?.id) {
    return (
      <Tabs
        size={TabLinkSize.SMALL}
        className="pb-24"
        tabs={tabs}
        tabListClassName={clsx(
          "px-6 sticky z-2 items-end",
          {
            "h-12 top-0": showSidebar,
            "h-8 top-20": !showSidebar,
          },
          bgColorClass.neutral.subtlest.enabled
        )}
      />
    );
  }

  return <ContractSearch />;
}
