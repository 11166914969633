import { captureException } from "@sentry/browser";
import { useAtom, useAtomValue } from "jotai";
import Cookies from "js-cookie";
import { useState } from "react";
import useCookie from "../../hooks/useCookie";
import { userBonfireDetailsState, userStateState } from "../../jotai/user";
import { getPasswordErrorMessage } from "../../library/PasswordInput";
import type { BuyerLeadAgencyFieldValue } from "../../library/form/BuyerLeadAgencyField";
import { handleError, patchUserState, postSignup } from "../../utils/api";
import { SIGNUP_ORIGIN_COOKIE_KEY } from "../../utils/constants";
import {
  LoginType,
  SignupOrigin,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../utils/enums";
import {
  changeHeapEventLoginStatus,
  trackSignupFlowFailure,
  trackSignupSuccess,
} from "../../utils/tracking";
import { appendInitialCompleteAccountValues } from "./helpers";
import { type BuyerSignupFormValues, WindowType } from "./types";

type SubmitBuyerInfo = BuyerSignupFormValues & {
  password: string;
  buyerProfile: BuyerLeadAgencyFieldValue;
};

export default function useSubmitBuyerInfo({
  email,
  onComplete,
  prefilledBLA,
  prefilledBLAId,
}: {
  email: string;
  onComplete: (redirectUrl: string) => void;
  prefilledBLA: string;
  prefilledBLAId: string | null;
}): [
  boolean,
  string,
  (
    values: SubmitBuyerInfo,
    setFieldError: (name: string, message?: string) => void
  ) => Promise<void>,
] {
  const [loading, setLoading] = useState(false);
  const bonfireDetails = useAtomValue(userBonfireDetailsState);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [userState, setUserState] = useAtom(userStateState);
  const [signupOrigin] = useCookie<SignupOrigin>(
    SIGNUP_ORIGIN_COOKIE_KEY,
    SignupOrigin.UNKNOWN
  );

  async function submitBuyerInfo(
    { name, buyerProfile, buyerRole, ...values }: SubmitBuyerInfo,
    setFieldError: (name: string, message?: string) => void
  ) {
    setLoading(true);

    let form = new FormData();
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1] || "";
    form = appendInitialCompleteAccountValues(
      form,
      { firstName, lastName, ...values },
      email
    );
    form.append("bonfire_id", bonfireDetails?.userId);
    form.append(
      "governmentAffiliation",
      buyerProfile.governmentAffiliationDisplayName || ""
    );
    if (buyerProfile.governmentAgencyId) {
      form.append("blaID", buyerProfile.governmentAgencyId);
    }
    form.append("role", buyerRole || "");

    let response: Response;
    try {
      response = await postSignup(form);
    } catch (error) {
      // Handle uncaught exceptions. These aren't from the API, but rather if something goes wrong
      // clientside.
      const message = "An error occurred. Please try again.";
      setFormErrorMessage(message);
      captureException(error);
      trackSignupFlowFailure({
        emailEntered: email,
        loginType: LoginType.PAVILION,
        signupStep: loginSignupSteps.SIGNUP,
        error: message,
        accountType: loginSignupAccountTypes.BUYER,
        loginExperience: WindowType.Modal,
      });
      return;
    } finally {
      setLoading(false);
    }

    if (
      handleError(response, {
        logToSentry: true,
        log400ErrorsToSentry: false,
      })
    ) {
      if (response.status === 400) {
        const error = await response.json();
        // When signing up, we use a form that specifically validates password1
        // https://django-allauth.readthedocs.io/en/latest/forms.html
        if (error?.password1) {
          const passwordErrorMessage = getPasswordErrorMessage(error.password1);
          setFieldError("password", passwordErrorMessage);
          trackSignupFlowFailure({
            emailEntered: email,
            loginType: LoginType.PAVILION,
            signupStep: loginSignupSteps.SIGNUP,
            error: passwordErrorMessage,
            accountType: loginSignupAccountTypes.BUYER,
            loginExperience: WindowType.Modal,
          });
        }
      }
      return;
    }

    changeHeapEventLoginStatus(true);
    trackSignupSuccess({
      prefilledBLA,
      prefilledBLAId,
      accountType: loginSignupAccountTypes.BUYER,
      loginType: LoginType.PAVILION,
      emailEntered: email,
      entitySelected: buyerProfile.governmentAffiliationDisplayName,
      entityAutofilled: !!prefilledBLA,
      signupOrigin,
    });
    Cookies.remove(SIGNUP_ORIGIN_COOKIE_KEY);
    // Our signup form says, "By clicking 'Create account,' you agree to the policy"
    const updatedState = { ...userState, hasAcceptedPrivacyPolicy: true };
    patchUserState(updatedState);
    setUserState(updatedState);
    onComplete(response.url);
  }

  return [loading, formErrorMessage, submitBuyerInfo];
}
