import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import clsx from "clsx";
import quoteTeam from "../../../img/team-icons/quote-team.png";
import { Badge, Typography } from "../../library";

export default function QuoteRequestRepsOnline({
  size = "md",
  row = false,
}: { size?: "sm" | "md"; row?: boolean }) {
  return (
    <div
      className={clsx("flex items-center", {
        "flex-row justify-between": row,
        "flex-col-reverse justify-center": !row,
      })}
    >
      <Badge
        Icon={FiberManualRecordIcon}
        size={size === "md" ? "sm-md" : size}
        className="gap-1"
        iconClass="text-cp-limeade-700"
      >
        <Typography size="sm" color="neutral.boldest.enabled">
          Pavilion reps online
        </Typography>
      </Badge>
      <img
        className={clsx("object-scale-down", {
          "h-12": size === "sm",
          "h-16": size === "md",
        })}
        src={quoteTeam}
        alt="Pavilion team member profiles"
      />
    </div>
  );
}
