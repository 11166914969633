import { type ReactNode, useEffect } from "react";
import useShowPiggybackLaunchModal from "../../components/EntityContractsPage/PiggybackedContracts/useShowPiggybackLaunchModal";
import useInitializeUser from "../../hooks/useInitializeUser";
import AnnouncementBanner from "../../shared/AnnouncementBanner";
import Header from "../../shared/Header";
import MinimalPageHeader from "../../shared/Header/MinimalPageHeader";
import Sidebar from "../../shared/Sidebar";
import CollapsibleSidebar from "../../shared/Sidebar/CollapsibleSidebar";
import { useShowSidebar } from "../../shared/Sidebar/useShowSidebar";
import {
  PageType,
  type ProfileTypes,
  type SearchSource,
} from "../../utils/enums";
import Footer from "../Footer";
import ModalConductor from "./ModalConductor";
import PopupConductor from "./PopupConductor";
import { useHydratePage } from "./useHydratePage";

interface PageProps {
  children?: ReactNode;
  isSSR?: boolean;
  searchSource?: string;
  showHeader?: boolean;
  showBanner?: boolean;
  pageType?: PageType;
  pageId?: string;
  pageTitle?: string;
  isAuthenticated?: boolean;
  profileType: Maybe<ProfileTypes>;
  activeAgreements: Maybe<string>;
  context?: Maybe<string>;
}

const PROJECT_HEADER_PAGE_TYPES = [
  PageType.CONTRACT,
  PageType.SUPPLIER,
  PageType.SOLICITATION,
  PageType.CONTRACT_SOLICITATION,
];

export default function Page({
  children,
  isSSR = false,
  searchSource,
  showHeader = true,
  showBanner = true,
  ...rest
}: PageProps) {
  useHydratePage(rest, isSSR);

  const showSidebar = useShowSidebar();
  const initializeUser = useInitializeUser();

  // biome-ignore lint/correctness/useExhaustiveDependencies: Run only once on mount.
  useEffect(() => {
    initializeUser();
  }, []);

  useShowPiggybackLaunchModal();

  if (showHeader && showSidebar) {
    const showProjectHeader =
      rest.pageType && PROJECT_HEADER_PAGE_TYPES.includes(rest.pageType);
    return (
      <>
        <CollapsibleSidebar />
        <PopupConductor />
        <div className="flex">
          <Sidebar className="fixed top-0 hidden lg:flex" />
          <div className="flex min-h-screen flex-col justify-between lg:ml-[240px] lg:w-[calc(100vw-240px)]">
            {showBanner && <AnnouncementBanner />}
            {showProjectHeader && <MinimalPageHeader />}
            {children}
            <Footer />
          </div>
        </div>
        <ModalConductor />
      </>
    );
  }

  return (
    <>
      {showHeader && (
        <Header searchSource={searchSource as SearchSource} isSSR={isSSR} />
      )}
      {showBanner && <AnnouncementBanner />}
      <PopupConductor />
      {children}
      <ModalConductor />
    </>
  );
}

export type AuthPageProps = {
  is_authenticated?: boolean;
  profile_type?: ProfileTypes;
  active_agreements?: Maybe<string>;
  context?: Maybe<string>;
  isSSR?: boolean;
};
