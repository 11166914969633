import { Field, Form, Formik, type FormikHelpers } from "formik";
import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { userEmailState, userPasswordAtom } from "../../../jotai/user";
import Typography from "../../../library/Typography";
import {
  LoginType,
  loginSignupAccountTypes,
  loginSignupSteps,
} from "../../../utils/enums";
import { trackSignupFlowFailure } from "../../../utils/tracking";
import type { SupplierSignupFormValues, WindowType } from "../types";

import { supplierFieldSignupState } from "../../../jotai/signup";
import FormSubmitButton from "../../../library/form/FormSubmitButton";
import type { Validate } from "../../../library/form/types";
import { getParams } from "../../../utils";
import yup from "../../../utils/yupPhone";
import useSubmitSupplierInfo from "../useSubmitSupplierInfo";
import StepCircle from "./StepCircle";
import { NAME_FIELD, PHONE_FIELD, SUPPLIER_ROLE_FIELD } from "./constants";

interface SupplierSignupFormProps {
  onComplete: (redirectUrl: string) => void;
  parentWindow: WindowType;
}

export default function SupplierSignupForm({
  onComplete,
  parentWindow,
}: SupplierSignupFormProps) {
  const email = useAtomValue(userEmailState);
  const password = useAtomValue(userPasswordAtom);
  const supplierFromSignup = useAtomValue(supplierFieldSignupState);
  const urlParams = getParams();
  const isSupplierInvite =
    !!urlParams.supplierInvite &&
    !!urlParams.supplierHandle &&
    !!urlParams.supplierName &&
    !!urlParams.email;

  const [loading, formErrorMessage, submitSupplierInfo] = useSubmitSupplierInfo(
    {
      email,
      onComplete,
      isSupplierInvite,
    }
  );

  const validationSchema = useMemo(() => {
    return [NAME_FIELD, PHONE_FIELD, SUPPLIER_ROLE_FIELD].reduce(
      (schema: Record<string, Validate>, { name, validate }) => {
        if (validate) schema[name] = validate;
        return schema;
      },
      {}
    );
  }, []);

  function trackInvalidForm(error: string) {
    trackSignupFlowFailure({
      emailEntered: email,
      accountType: loginSignupAccountTypes.BUYER,
      loginType: LoginType.PAVILION,
      signupStep: loginSignupSteps.SIGNUP,
      error: JSON.stringify(error),
      loginExperience: parentWindow,
    });
  }

  const handleSubmit = async (
    values: SupplierSignupFormValues,
    helpers: FormikHelpers<SupplierSignupFormValues>
  ) => {
    if (!supplierFromSignup) {
      trackInvalidForm("Expected supplierFromSignup to exist");
    }

    return submitSupplierInfo(
      {
        ...values,
        password,
        supplier: supplierFromSignup,
      },
      helpers.setFieldError
    );
  };
  // TODO: Use entrypoint to customize copy for 3rd step
  // https://app.shortcut.com/coprocure/story/24980/handle-copy-variants-based-on-entry-point
  const stepFromEntrypoint = "View contract";
  const steps = ["Sign up", "Create account", stepFromEntrypoint];

  return (
    <div className="grid gap-6">
      <div className="flex gap-3">
        {steps.map((text, index) => (
          <StepCircle key={text} text={text} index={index} />
        ))}
      </div>
      <div className="grid gap-4">
        <div className="grid gap-3">
          <Typography
            variant="headline"
            size="sm"
            color="neutral.boldest.enabled"
            emphasis
          >
            Let's set up Pavilion for you
          </Typography>
          <Typography color="neutral.boldest.enabled">
            Tell us about yourself so we can customize your experience.
          </Typography>
        </div>
        <div className="grid gap-2">
          <Formik
            enableReinitialize
            validateOnBlur
            initialValues={
              {
                name: "",
                phone: "",
                phoneExtension: "",
                supplierRole: null,
              } as SupplierSignupFormValues
            }
            onSubmit={handleSubmit}
            validationSchema={yup.object(validationSchema)}
          >
            <Form className="grid gap-4">
              <Field {...NAME_FIELD} editable />
              <Field {...PHONE_FIELD} editable />
              <Field {...SUPPLIER_ROLE_FIELD} anchor="top start" editable />
              <FormSubmitButton
                analyticsClassName="analytics-supplier-signup-modal-cta"
                dataTestId="submit-create-account"
                disabled={loading}
                trackInvalidForm={trackInvalidForm}
              >
                Create account
              </FormSubmitButton>
            </Form>
          </Formik>
          {formErrorMessage && (
            <Typography size="sm" color="destructive.default.primary.enabled">
              {formErrorMessage}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
