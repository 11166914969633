/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * * `IMMEDIATE` - Immediate
 * * `1_2_MONTHS` - One To Two Months
 * * `2_6_MONTHS` - Two To Six Months
 * * `6_12_MONTHS` - Six To Twelve Months
 * * `NOT_APPLICABLE` - Not Applicable
 */
export enum TimelineEnum {
    IMMEDIATE = 'IMMEDIATE',
    _1_2_MONTHS = '1_2_MONTHS',
    _2_6_MONTHS = '2_6_MONTHS',
    _6_12_MONTHS = '6_12_MONTHS',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}
